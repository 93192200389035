import React from "react";
import { Link } from "react-router-dom";

const AboutThree = () => {
  return (
    <>
      {/* <!-- tp-about-3 start  --> */}
      <div className="tp-ab-section pt-160">
        <div className="container">
          <div className="row align-items-center tp-flex-reverse">
            <div className="col-lg-6">
              <div className="tp-ab-info pr-40">
                <div className="tp-ab-subtitle mb-10">
                  <span>Advokat Tepić</span>
                </div>
                <h3 className="tp-ab-title mb-35">
                  Vaš Partner u pravnim rješenjima
                </h3>
                <p>
                  Dobrodošli na sajt kancelarije Tepić, gdje pravna rješenja
                  postaju stvarnost. Kao vaš pouzdani partner u pravnim
                  pitanjima, tu smo da Vas sa lakoćom i sigurnošću vodimo kroz
                  složene pravne procese.
                </p>
                <p>
                  Sa strašću za pronalaženje rešenja i posvećenošću našim
                  klijentima, pružamo pravne usluge sa visokim nivoom
                  profesionalizma i pažnje prema detaljima. Bez obzira na
                  prirodu vaših pravnih problema, spremni smo da se suočimo s
                  njima i pružimo Vam efikasna rešenja koja su Vam potrebna.
                </p>
                <div>
                  <Link to="/usluge" className="tp-btn">
                    Sve usluge
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-ab-img text-md-end w-img">
                <img src="assets/img/about/about-2.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- tp-about-3 end  --> */}
    </>
  );
};

export default AboutThree;
