import React from "react";
import { Link } from "react-router-dom";

const TopCategoryHomePageTwo = () => {
  return (
    <>
      <div className="tp-cta-area-2 grey-bg-4">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-12 col-xl-4">
              <div className="tp-cta-2-left black-bg pt-135 pr-30 pl-90 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20">
                    ADVOKAT TEPIĆ
                  </span>
                  <h2 className="tp-section-title text-white tp-title-size">
                    Biografija
                  </h2>
                  <p className="pt-10">
                    Gojko-Nemanja Tepić, završio je Pravni fakultet Univerziteta
                    u Banja Luci u periodu od 2014. – 2018. godine. Na četvrtoj
                    godini studija započeo je rad u jednoj od globalno najvećih
                    konsultantskih kuća, gdje je kroz servis domaćim i stranim
                    klijentima i interne potrebe društva, stekao značajno
                    iskustvo u privrednoj oblasti i pružanju konsultantskih
                    pravnih usluga.
                  </p>
                  <p className="pt-10">
                    Izabran je za Arbitra/Miritelja za rješavanje radnih sporova
                    pred Agencijom za mirno rješavanje radnih sporova 2023.
                    godine.
                  </p>

                  <div className="mt-50">
                    <Link to="/usluge" className="tp-btn-white">
                      Usluge
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-img w-img">
                <img src="assets/img/cta/cta.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-2-right white-bg pt-135 pr-30 pl-75 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20">
                    ADVOKAT TEPIĆ
                  </span>
                  <h2 className="tp-section-title tp-title-size">
                    Dostignuća i članstva
                  </h2>

                  <p className="pt-10">
                    Pravosudni ispit je položio 2021. godine, a advokatski ispit
                    2023. godine kada se upisao u Advokatsku komoru Republike
                    Srpske kao jedan od najmlađih advokata.
                  </p>

                  <p className="pt-10">
                    Radno iskutvo:
                    <br /> Grant Thornton d.o.o. Banja Luka 2018. – 2023. godine{" "}
                    <br />
                    AK Tepić – 2023. godine – trenutno
                  </p>
                  <p>
                    Članstva:
                    <br /> 2023. godine - Advokatska komora RS
                    <br />
                    2023. godine – Agencija za mirno rješavanje radnih sporova
                    (Arbitar / Miritelj)
                  </p>
                  <div className="mt-50">
                    <Link to="/kontakt" className="tp-btn-border">
                      Kontakt
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCategoryHomePageTwo;
