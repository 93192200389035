const menu_data = [
  {
    id: 2,
    title: "Početna",
    link: "/",
  },
  {
    id: 2,
    title: "Usluge",
    link: "/usluge",
  },
  {
    id: 3,
    title: "klijenti",
    link: "/klijenti",
  },
  {
    id: 4,
    title: "Kontakt",
    link: "/kontakt",
  },
];

export default menu_data;
