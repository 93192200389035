import React from "react";
import { Link } from "react-router-dom";

const FullScreenBtn = () => {
  return (
    <>
      {/* <!-- full screen btn area  --> */}
      <div className="tp-theme-btn-area pt-90 pb-140">
        <div className="container"></div>
      </div>
      {/* <!-- full screeen btn are end  --> */}
    </>
  );
};

export default FullScreenBtn;
